body {
  margin: 0;
  height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, 'Noto-Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:root {
  height: 100%;

  --fb_edge_question_large: 26px;

  --fb_edge_question_medium: 24px;

  --fb_edge_question_small: 22px;

  --fb_edge_description_large: 20px;

  --fb_edge_description_medium: 18px;

  --fb_edge_description_small: 16px;

  --fb_question_large: 24px;

  --fb_question_medium: 22px;

  --fb_question_small: 20px;

  --fb_question_description_large: 19px;

  --fb_question_description_medium: 17px;

  --fb_question_description_small: 15px;
}

@media only screen and (max-width: 414px) {
  :root {
    --fb_edge_question_large: 22px;

    --fb_edge_question_medium: 20px;

    --fb_edge_question_small: 18px;

    --fb_edge_description_large: 16px;

    --fb_edge_description_medium: 14px;

    --fb_edge_description_small: 12px;

    --fb_question_large: 18px;

    --fb_question_medium: 16px;

    --fb_question_small: 14px;

    --fb_question_description_large: 15px;

    --fb_question_description_medium: 13px;

    --fb_question_description_small: 11px;
  }
}
